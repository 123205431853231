import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Telegram, Youtube } from "react-bootstrap-icons";
import { HashLink } from "react-router-hash-link";
import ConnectButton from "./../components/ConnectButton";
import { useDispatch } from "react-redux";
import { changeToDark, changeToLight } from "../features/theme";
import turkeyFlag from "../assets/images/turkey.png";
import usaFlag from "../assets/images/united-states.png";
import deFlag from "../assets/images/germany.png";
import plFlag from "../assets/images/poland.png";

import polSmartLogo from "./../assets/images/polsmart_logo_dark.png";
import polSmartLogoWhite from "./../assets/images/polsmart_logo_white.png";

function Header() {
  const dispatch = useDispatch();
  const [headerFix, setheaderFix] = React.useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
      if (window.scrollY > 50) dispatch(changeToDark());
      if (window.scrollY < 50) dispatch(changeToLight());
    });
  }, []);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <header className="site-header mo-left header header-transparent style-1">
        <div
          className={`sticky-header main-bar-wraper navbar-expand-lg ${
            headerFix ? "is-fixed" : ""
          }`}>
          <div className="main-bar clearfix">
            <div className="container clearfix">
              <div className="logo-header">
                <Link to={"/"} className="logo-dark">
                  <img src={polSmartLogo} alt="" />
                </Link>
                <Link to={"/"} className="logo-light">
                  <img src={polSmartLogoWhite} alt="" />
                </Link>
              </div>

              <button
                type="button"
                className={`navbar-toggler  navicon justify-content-end ${
                  sidebarOpen ? "open" : "collapsed"
                }`}
                onClick={() => setSidebarOpen(!sidebarOpen)}>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="extra-nav">
                <div className="extra-cell">
                  <ConnectButton />
                </div>
              </div>

              <div
                className={`header-nav navbar-collapse collapse justify-content-end ${
                  sidebarOpen ? "show" : ""
                }`}
                id="navbarNavDropdown">
                <div className="logo-header mostion">
                  <NavLink to={"#"} className="logo-dark">
                    <img src={polSmartLogo} alt="" width={150} />
                  </NavLink>
                </div>
                <ul className="nav navbar-nav navbar">
                  <li>
                    <NavLink to={"/"}>Home</NavLink>
                  </li>
                  <li>
                    <HashLink smooth to="/#about-us">
                      About Us
                    </HashLink>
                  </li>
                  <li
                    className={`sub-menu-down ${showMenu ? "open" : ""}`}
                    id="menushow"
                    onClick={() => setShowMenu(!showMenu)}>
                    <Link to={"#"}>Presentation</Link>
                    <ul className="sub-menu">
                      <li>
                        <NavLink
                          target="_blank"
                          to={"/PolSmart_Presentation_EN.pdf"}
                          style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={usaFlag}
                            alt="usaFlag"
                            width={20}
                            style={{ marginRight: 10 }}
                          />
                          English
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          target="_blank"
                          to={"/PolSmart_Presentation_TR.pdf"}
                          style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={turkeyFlag}
                            alt="turkeyFlag"
                            width={20}
                            style={{ marginRight: 10 }}
                          />
                          Turkish
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          target="_blank"
                          to={"/PolSmart_Presentation_DEU.pdf"}
                          style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={deFlag}
                            alt="deFlag"
                            width={20}
                            style={{ marginRight: 10 }}
                          />
                          German
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          target="_blank"
                          to={"/PolSmart_Presentation_PLK.pdf"}
                          style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={plFlag}
                            alt="plFlag"
                            width={20}
                            style={{ marginRight: 10 }}
                          />
                          Polish
                        </NavLink>
                      </li>

                      
                      {/*<li>
                        <NavLink
                          to={"/blog-grid"}
                          style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={turkeyFlag}
                            alt="trFlag"
                            width={20}
                            style={{ marginRight: 10 }}
                          />
                          Turkish
                        </NavLink>
              </li>*/}
                      {/*<li><NavLink to={"/blog-details"}>France</NavLink></li>*/}
                    </ul>
                  </li>
                
                  <li>
                    <NavLink to={"https://t.me/maticfund_chat"}>
                      <Telegram color="#019ad2" size={32} />
                    </NavLink>
                  </li>
                </ul>

                <div className="header-bottom">
                  <div className="dz-social-icon">
                    <ul>
                      <li>
                        <NavLink to={"https://t.me/maticfund_chat"}>
                          <Telegram color="#019ad2" size={32} />
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
