import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { UseSelector, useSelector } from "react-redux";

//images
import shape1 from "./../assets/images/home-banner/shape1.png";
import bgimage from "./../assets/images/background/bg1.jpg";
import logowhite from "./../assets/images/logo-white.png";
import turkeyFlag from "../assets/images/turkey.png";
import usaFlag from "../assets/images/united-states.png";
import deFlag from "../assets/images/germany.png";
import plFlag from "../assets/images/poland.png";import Moment from "react-moment";

import "moment-timezone";

function Footer() {
  const { news } = useSelector((state) => state.theme);
  return (
    <>
      <footer className="site-footer style-1" id="footer">
        <img className="bg-shape1" src={shape1} alt="" />
        <div
          className="footer-top background-luminosity"
          style={{ backgroundImage: "url(" + bgimage + ")" }}>
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12">
                <div className="widget widget_about">
                  <div className="footer-logo logo-white">
                    <Link to={"/"}>
                      <img src={logowhite} alt="" />
                    </Link>
                  </div>
                  <p>
                    Pol Smart Smart Contract Capable of Trading Pol/USD Earn
                    daily profits 0.7% by depositing POL(POLYGON)
                  </p>
                  <div className="dz-social-icon transparent space-10">
                    <ul>
                      <li>
                        <button
                          target="_blank"
                          className="fab fa-telegram"
                          href="https://t.me/maticfund_chat"></button>
                      </li>{" "}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6">
                <div className="widget widget_services">
                  <h4 className="widget-title">Other Links</h4>
                  <ul>
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>
                      <HashLink smooth to="/#about-us">
                        About Us
                      </HashLink>
                    </li>
                    <li>
                      <HashLink smooth to="/#invest">
                        Start Invest
                      </HashLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="widget recent-posts-entry">
                  <h4 className="widget-title">Polygon News</h4>
                  <div className="widget-post-bx">
                    {news.slice(0, 2).map((newsSingle, index) => (
                      <div key={index}>
                        <div className="widget-post clearfix">
                          <div className="dz-info">
                            <h6 className="title">
                              <Link to={newsSingle.link}>
                                {newsSingle.title}
                              </Link>
                            </h6>
                            <span className="post-date">
                              <Moment
                                className="text-wrap"
                                format="DD-MM-YYYY"
                                tz={
                                  Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone
                                }>
                                {newsSingle.pubDate}
                              </Moment>{" "}
                            </span>
                          </div>
                        </div>
                        <div className="post-separator"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="widget recent-posts-entry">
                  <h4 className="widget-title">Presentation</h4>
                  <div className="widget-post-bx">
                    <div className="widget-post clearfix">
                      <div className="dz-info">
                        <h6 className="title d-flex flex-column gap-2">
                          <Link
                            target="_blank"
                            to={"/PolSmart_Presentation_EN.pdf"}>
                            <img src={usaFlag} alt="usaFlag" width={20} style={{ marginRight: 10 }} />
                            English Presentation
                          </Link>
                          <Link
                            target="_blank"
                            to={"/PolSmart_Presentation_TR.pdf"}>
                            <img src={turkeyFlag} alt="turkeyFlag" width={20} style={{ marginRight: 10 }} />
                            Turkish Presentation
                          </Link>
                          <Link
                            target="_blank"
                            to={"/PolSmart_Presentation_DEU.pdf"}>
                            <img src={deFlag} alt="deFlag" width={20} style={{ marginRight: 10 }} />
                            German Presentation
                          </Link>

                          <Link
                            target="_blank"
                            to={"/PolSmart_Presentation_PLK.pdf"}>
                            <img src={plFlag} alt="plFlag" width={20} style={{ marginRight: 10 }} />
                            Polish Presentation
                          </Link>
                        </h6>
                      </div>
                    </div>
                    <div className="post-separator"></div>
                    {/*<div className="widget-post clearfix">
                      <div className="dz-info">
                        <h6 className="title">
                          <Link to={"/"}>Turkish Presentation</Link>
                        </h6>
                      </div>
                              </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom text-center">
          <div className="container">
            <span className="copyright-text">
              Copyright © 2024{" "}
              <a href="https://polsmart.io/" target="_blank" rel="noreferrer">
                PolSmart
              </a>
              . All rights reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
