import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  price: 0.0,
  totalInvest: 0,
  totalInvestCount: 0,
  balance: 0,
  percentage: 0,
  tradeProfit: 0,
};
export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setContractStats: (state, action) => {
      state.totalInvest = action.payload.totalInvest;
      state.totalInvestUsd = action.payload.totalInvestUsd;
      state.totalActiveInvest = action.payload.totalActiveInvest;
      state.totalActiveInvestUsd = action.payload.totalActiveInvestUsd;
      state.totalInvestCount = action.payload.totalInvestCount;
      state.totalWithdrawn = action.payload.totalWithdrawn;
      state.totalWithdrawnUsd = action.payload.totalWithdrawnUsd;
      state.balance = action.payload.balance;
      state.percentage = action.payload.percentage;
    },
  },
});

export const { setPrice, setContractStats } = contractSlice.actions;

export default contractSlice.reducer;
